<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>修改密码</span>
      </div>
    </div>
    <div class="head_img">
      <img src="@/assets/images/loginview.png" alt="" />
    </div>
    <div class="ers">
      <van-field v-model="formdata.tel" type="tel" label="请输入手机号" />
      <van-field v-model="formdata.yzCode" label="验证码" />
      <van-field v-model="formdata.passWorld" label="请输入密码" />
      <span class="revfd" @click="yanz">{{ authCode }}</span>
      <p></p>
    </div>
    <van-button class="btn" type="primary" size="large" @click="register"
      >确定</van-button
    >
  </div>
</template>

<script>
import { getSmsCode, updatePassWord } from "@/request/api";
export default {
  data() {
    return {
      formdata: {
        tel: "",
        yzCode: "",
        passWorld: "",
      },
      authCode: "发送验证码",
      flag: true,
    };
  },
  methods: {
    //   发送验证码
    yanz() {
      if (this.flag) {
        let that = this;
        console.log(this.formdata.tel);
        if (
          this.formdata.tel != "" &&
          /^1[3456789]\d{9}$/.test(this.formdata.tel)
        ) {
          getSmsCode({
            mobile: this.formdata.tel,
            type: 2,
          }).then((res) => {
            console.log(res);
            // if(res.data)
            if (res.code == "200") {
              this.flag = false;
              this.authCode = "60";
              var clear = setInterval(function () {
                if (that.authCode == 0) {
                  that.authCode = "发送验证码";
                  clearInterval(clear);
                  that.flag = true;
                } else {
                  that.authCode--;
                }
              }, 1000);
            } else {
              this.$notify({ type: "warning", message: res.message });
            }
          });
        } else {
          this.$notify({ type: "warning", message: "请输入手机号" });
        }
      }
    },
    // 找回密码确定
    register() {
      if (
        this.formdata.passWorld != "" &&
        this.formdata.tel != "" &&
        this.formdata.yzCode != ""
      ) {
        updatePassWord({
          code: this.formdata.yzCode,
          loginName: this.formdata.tel,
          newPassWord: this.formdata.passWorld,
        }).then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$notify({ type: "success", message: "修改成功" });
            this.$router.push("/login");
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        });
      } else {
        this.$notify({ type: "warning", message: "请输入完整的信息" });
      }
    },

    goback() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
.head_img {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
.ers {
  margin-top: 10%;
}
.ers {
  position: relative;
  .revfd {
    position: absolute;
    top: 11%;
    left: 77%;
    color: #1296db;
    font-size: 0.8rem;
  }
}
.btn {
  margin-top: 20%;
}
</style>